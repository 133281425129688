/* eslint-disable indent */
import { Button } from '@pulse-web-ui/button';
import { Input } from '@pulse-web-ui/input';
import { css, media, mixins, styled, typography } from '@pulse-web-ui/theme';

export const PhoneVerificationContainer = styled.div`
  ${media.desktop} {
    width: 376px;
  }
`;

export const CodeContainer = styled.div`
  ${mixins.flex({})};
`;

export const CodeInputWrapper = styled.div`
  width: calc(100% - 56px);

  ${media.desktop} {
    width: 224px;
  }
`;

export const ReturnToPhoneChangeButton = styled.div`
  ${typography.regular14};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.interactive};
  cursor: pointer;
  margin-top: 8px;
`;

export const StyledButton = styled(Button)<{
  error?: string;
}>`
  border-radius: ${({ theme }) => {
    const radius = theme.common.radius12;

    return `0 ${radius} ${radius} 0`;
  }};
  z-index: 0;
  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.colors.text.error};
      background: ${theme.colors.text.error};
      &:hover {
        background: ${theme.colors.text.error} !important;
        border: 1px solid ${theme.colors.text.error} !important;
      }
    `}
`;

export const StyledSiteAgreements = styled.div`
  ${typography.regular14}
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.secondary};

  a {
    color: ${({ theme }) =>
      theme.common?.a?.color
        ? theme.common.a.color
        : theme.colors.text.interactive};
    text-decoration: ${({ theme }) =>
      theme.common?.a?.decoration ? theme.common.a.decoration : 'none'};
  }
`;

export const StyledPolicyLink = styled.a`
  display: inline-block;

  ${media.desktop} {
    display: block;
  }
`;
export const StyledOTPInput = styled(Input)`
  border-radius: ${({ theme }) => {
    const radius = theme.common.radius12;

    return `${radius} 0 0 ${radius}`;
  }};

  &,
  &:hover,
  &:focus {
    border-right: none;
    border-right-width: 0;
  }
`;
