import React, { VFC, memo } from 'react';

import { Button } from '@pulse-web-ui/button';
import { useTheme } from '@pulse-web-ui/theme';

import {
  StyledContentGroup,
  StyledDesc,
  StyledIcon,
  StyledTitle,
  StyledWrapper,
} from './empty-message.styles';
import { EmptyMessageProps } from './empty-message.types';

export const EmptyMessage: VFC<EmptyMessageProps> = memo(
  ({ title, description, handleOnMouseDown, buttonLabel }) => {
    const theme: any = useTheme();

    return (
      <StyledWrapper>
        <StyledIcon width={26} color={theme.colors.icon.primary} />
        <StyledContentGroup>
          <StyledTitle>{title}</StyledTitle>
          <StyledDesc>{description}</StyledDesc>
          <Button
            variant="text"
            onMouseDown={handleOnMouseDown}
            label={buttonLabel}
          />
        </StyledContentGroup>
      </StyledWrapper>
    );
  }
);
