import { Info } from '@pulse-web-ui/icons';
import { mixins, styled, typography } from '@pulse-web-ui/theme';

export const StyledWrapper = styled.div`
  ${mixins.flex({ gap: 7, wrap: false })};
  padding-left: 18px;
`;

export const StyledContentGroup = styled.div`
  & > * {
    ${typography.regular16};
    line-height: 24px;
  }
`;

export const StyledTitle = styled.div`
  margin-bottom: 2px;
  ${typography.medium16}
  line-height: 24px;
`;

export const StyledDesc = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: 8px;
`;

export const StyledIcon = styled(Info)`
  flex-shrink: 0;
`;
