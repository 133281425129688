/* eslint-disable indent */
import { useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { isBrowser } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { DisplayQRButton } from '@pulse-web-ui/display-qr-button';
import { RGSFilter, RGSLicence, RGSMessage } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';

import {
  PageSubTitleRegular18,
  PageTitle,
  StyledFormSub,
} from '@src/common-components/headers';
import {
  GridColumnContainer,
  HeaderSubMedium18,
  UniversalColumn,
} from '@src/components';
import { AnalyticsType } from '@src/components/web-analytic';
import { POLICY_PRICE_MULTIPLIER } from '@src/components/web-analytic/constants';
import {
  sendAnalyticEvent,
  sendUserIdToAnalytics,
} from '@src/components/web-analytic/utils';
import {
  CurrencyCode,
  Product,
  analyticEvents,
  nonSubscriptionProducts,
  salesNinjaEvents,
} from '@src/constants';
import { RGSSupportBlock } from '@src/features';
import { useIsDesktop } from '@src/hooks';
import {
  StyledPayCard,
  StyledPayCardImageWrapper,
  StyledPayCardText,
  StyledPayCards,
} from '@src/pages/best-to-pay/best-to-pay-page.styles';
import {
  clearLocalStorageOnSuccessPayment,
  cleartDraft,
  getDeeplink,
  useScrollToIframeOnMount,
} from '@src/utils';

import { BaseLayout } from '../../layouts/base-layout';
import { StyledSubtitleWrapper } from './best-to-pay-success-page.styles';
import { useHandleGoBack } from './hooks';

export const BestToPaySuccessPage: FC = () => {
  const { t } = useTranslation();
  useHandleGoBack('success');
  const isDesktop = useIsDesktop();

  const [searchParams] = useSearchParams();
  const storedEmail = localStorage.getItem('email');
  const selectedDate = localStorage.getItem('selectedDate') as string;
  const currentSelectedProduct = localStorage.getItem('selectedProduct');
  const preset = localStorage.getItem('preset');
  const userId = localStorage.getItem('userId');
  const policyCost = localStorage.getItem('policyCost');
  const petsTemporaryFranchise = localStorage.getItem('petsTemporaryFranchise');
  const theme: any = useTheme();

  useScrollToIframeOnMount(currentSelectedProduct);

  const formatSelectedDate = useMemo(
    () =>
      new Date(JSON.parse(selectedDate)).toLocaleDateString('ru-RU', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }),
    [selectedDate]
  );
  const pageTitle = nonSubscriptionProducts.includes(
    currentSelectedProduct || ''
  )
    ? t('BEST_TO_PAY:headers.policyIssued')
    : t('BEST_TO_PAY:headers.yourPolicyIssued');

  const filterCardDescription = nonSubscriptionProducts.includes(
    currentSelectedProduct || ''
  )
    ? t('BEST_TO_PAY:hints.cancelInsurance')
    : t('BEST_TO_PAY:hints.controlSubscription');

  const petsTemporaryFranchiseHintOptions = {
    date: selectedDate ? formatSelectedDate.replace(' г.', '') : '',
    temporaryFranchise: petsTemporaryFranchise
      ? `. ${petsTemporaryFranchise}`
      : '',
  };

  const pageSubTitle = useMemo(() => {
    switch (currentSelectedProduct) {
      case Product.PETS:
        return (
          <StyledSubtitleWrapper>
            <span>
              <Trans values={petsTemporaryFranchiseHintOptions}>
                {t('BEST_TO_PAY:hints.sentToEmailWereInTouch')}
              </Trans>
            </span>
            <span>
              <Trans values={{ email: storedEmail || '' }}>
                {t('BEST_TO_PAY:hints.policyWasSent')}
              </Trans>
            </span>
          </StyledSubtitleWrapper>
        );
      default:
        return (
          <span>
            <Trans
              values={{
                date: selectedDate
                  ? formatSelectedDate.replace('г.', t('COMMON:hints.ofYear'))
                  : '',
                email: storedEmail || '',
              }}
            >
              {t('BEST_TO_PAY:hints.sentItToEmail')}
            </Trans>
          </span>
        );
    }
  }, [currentSelectedProduct, t]);

  const appButtonLabel = useMemo(() => {
    switch (currentSelectedProduct) {
      case Product.PETS:
      case Product.MITE:
        return t('COMMON:buttons.installApp');
      default:
        return t('COMMON:buttons.downloadApp');
    }
  }, [currentSelectedProduct, t]);

  useEffect(() => {
    if (userId) {
      sendUserIdToAnalytics(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (searchParams.get('reference')) {
      localStorage.setItem(
        'referenceSuccess',
        String(searchParams.get('reference'))
      );
      localStorage.setItem(
        'operationId',
        String(searchParams.get('operation'))
      );
    }

    switch (currentSelectedProduct) {
      case Product.APARTMENT:
        if (!!preset) {
          sendAnalyticEvent(analyticEvents.iflFlatPresetPaymentSuccess);
        } else {
          sendAnalyticEvent(analyticEvents.iflPaymentSuccess);
          sendAnalyticEvent(analyticEvents.iflFlatPaymentSuccess);
          sendAnalyticEvent(
            salesNinjaEvents.purchaseFlatPaymentSuccess,
            null,
            AnalyticsType.salesNinja
          );
        }

        break;
      case Product.HOUSE:
        if (!!preset) {
          sendAnalyticEvent(analyticEvents.iflHousePresetPaymentSuccess);
        } else {
          sendAnalyticEvent(analyticEvents.iflPaymentSuccess);
          sendAnalyticEvent(analyticEvents.iflHousePaymentSuccess);
          sendAnalyticEvent(
            salesNinjaEvents.purchaseHousePaymentSuccess,
            null,
            AnalyticsType.salesNinja
          );
        }
        break;
      case Product.PETS:
        const event = !!preset
          ? analyticEvents.petPaymentSuccessPreset
          : analyticEvents.petPaymentSuccess;
        sendAnalyticEvent(event);
        sendAnalyticEvent(
          salesNinjaEvents.purchasePetPaymentSuccess,
          null,
          AnalyticsType.salesNinja
        );
        break;

      case Product.NS:
        sendAnalyticEvent(analyticEvents.paymentSuccessAccident);
        sendAnalyticEvent(analyticEvents.nsPaymentSuccess);
        sendAnalyticEvent(
          salesNinjaEvents.purchaseNSPaymentSuccess,
          null,
          AnalyticsType.salesNinja
        );
        break;

      case Product.SPORT:
        const modifiedPolicyCost = policyCost
          ? Number(policyCost) * POLICY_PRICE_MULTIPLIER
          : null;

        sendAnalyticEvent(
          analyticEvents.sportPaymentSuccess,
          null,
          AnalyticsType.amplitude
        );
        sendAnalyticEvent(
          analyticEvents.sportPaymentSuccess,
          policyCost
            ? { order_price: modifiedPolicyCost, currency: CurrencyCode.RUB }
            : {},
          AnalyticsType.ym
        );
        sendAnalyticEvent(
          salesNinjaEvents.purchaseSportPaymentSuccess,
          policyCost ? { revenue: modifiedPolicyCost } : null,
          AnalyticsType.salesNinja
        );
        break;

      case Product.MITE:
        sendAnalyticEvent(analyticEvents.mitePaymentSuccess);
        sendAnalyticEvent(
          salesNinjaEvents.purchaseMitePaymentSuccess,
          null,
          AnalyticsType.salesNinja
        );
        break;

      case Product.PETS_TELEVET_PLUS:
        sendAnalyticEvent(analyticEvents.petTelPaySuccess);
        break;

      default:
        break;
    }
    clearLocalStorageOnSuccessPayment();
  }, []);

  useEffect(() => {
    cleartDraft();
  }, []);

  return (
    <BaseLayout verticalAlignment={true}>
      <GridColumnContainer gridStep={1}>
        <UniversalColumn align="left" gap={0}>
          <PageTitle>{pageTitle}</PageTitle>
          <PageSubTitleRegular18 marginBottom={32}>
            {pageSubTitle}
          </PageSubTitleRegular18>
        </UniversalColumn>
        <StyledFormSub margin="0 0 32px">
          {isBrowser ? (
            <DisplayQRButton buttonLabel={appButtonLabel} />
          ) : (
            <Button
              adaptiveWidth={!isDesktop}
              variant="primary"
              label={appButtonLabel}
              onClick={getDeeplink}
            />
          )}
        </StyledFormSub>
      </GridColumnContainer>
      <UniversalColumn align="left">
        <HeaderSubMedium18>
          {t('BEST_TO_PAY:headers.InApplicationYouCan')}
        </HeaderSubMedium18>
      </UniversalColumn>
      <StyledPayCards>
        <StyledPayCard>
          <StyledPayCardImageWrapper>
            <RGSFilter width={64} color={theme.colors.surface.brand2} />
          </StyledPayCardImageWrapper>
          <StyledPayCardText>{filterCardDescription}</StyledPayCardText>
        </StyledPayCard>
        <StyledPayCard>
          <StyledPayCardImageWrapper>
            <RGSMessage width={64} color={theme.colors.surface.brand2} />
          </StyledPayCardImageWrapper>
          <StyledPayCardText>
            {t('BEST_TO_PAY:hints.getAdvice')}
          </StyledPayCardText>
        </StyledPayCard>
        <StyledPayCard>
          <StyledPayCardImageWrapper>
            <RGSLicence width={64} color={theme.colors.surface.brand2} />
          </StyledPayCardImageWrapper>
          <StyledPayCardText>
            {t('BEST_TO_PAY:hints.getUsefulMaterialsAndDocuments')}
          </StyledPayCardText>
        </StyledPayCard>
      </StyledPayCards>
      <RGSSupportBlock />
    </BaseLayout>
  );
};
